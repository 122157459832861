import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled, theme } from "twin.macro"
import Lottie from "react-lottie"

import CouponContext from "context/coupon-context"
import MailIcon from "images/start/mail-verified.svg"
import IconUser from "images/forms/user.inline.svg"
import IconCompany from "images/forms/company.inline.svg"
import IconLock from "images/forms/lock.inline.svg"
import ModalLayout from "components/modal-layout"
import SEO from "components/seo"
import {
  Checkbox,
  Form,
  Hint,
  Submit,
  TextInput,
  Placeholder,
  FieldContainer,
} from "components/forms"
import { CountryDropdown } from "react-country-region-selector"
import waitAnimation from "images/start/running-circle.json"
import checkAnimation from "images/start/check-circle.json"
import { sendConversionEvent } from "utils/algolia"
import BasicBackgroundSection from "components/basic-background-section"
import BgStart from "images/bg/start.jpeg"
import StyledBlock from "components/newsroom/styled-block"

const Message = styled.div`
  h1 {
    ${tw`py-2 w-full font-display font-bold text-white text-center`}
    font-size: 28px;
    line-height: 28px;
  }

  .animation {
    transform: scale(0.6);

    svg {
      height: 100px;
    }
  }

  .check {
    path {
      fill: #5ef99a;
      stroke: #5ef99a;
    }
  }

  .wait {
    path {
      fill: none;
      stroke: white;
    }
  }
`
const GateStyle = styled.div`
  ${tw`font-body text-base text-center -mb-4`}

  p {
    padding-bottom: 1rem;
  }

  h2 {
    ${tw`text-2xl py-4`}
  }

  strong {
    ${tw`text-springgreen font-body font-normal`}
  }
`

const Coupon = styled.div`
  ${tw`flex flex-col items-center text-springgreen-light`}
  h3 {
    ${tw`font-display font-bold border-springgreen-light my-4`}
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }
`

const Container = styled.div`
  ${tw`py-16 m:pt-32 flex flex-wrap items-center justify-center overflow-hidden mx-auto`}

  & > div {
    width: 90%;

    @media (min-width: 640px) {
      ${tw`w-auto`}

      max-width: 570px;
    }
  }

  ${Form} {
    ${FieldContainer} {
      ${tw`my-1`}

      input[type="text"],
      input[type="password"] {
        ${tw`w-full`}
      }

      ${Placeholder} {
        top: -33px;
      }
    }

    ${Checkbox} {
      ${tw`bg-white py-3 px-4 border border-transparent`}

      border-radius: 5px;
      --tw-bg-opacity: 0.07;

      input {
        ${tw`appearance-none h-4 w-4 flex-shrink-0 box-border bg-white grid place-content-center outline-none`}

        box-shadow: none;
        border: 1.4px solid ${theme`colors.grey.60`};
        border-radius: 50%;
        -webkit-transform: none;

        &:hover {
          border-color: ${theme`colors.grey.35`};

          &::before {
            box-shadow: inset 9px 9px ${theme`colors.springgreen.dark`};
          }
        }

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 12px 12px ${theme`colors.springgreen`};
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      &:hover {
        --tw-bg-opacity: 0.12;
      }

      &.checked {
        ${tw`border-springgreen-secondary`}

        --tw-bg-opacity: 0.12;

        input {
          ${tw`border-springgreen`}

          filter: drop-shadow(0px 2px 10px rgba(5, 197, 107, 0.6));

          &:hover {
            border-color: ${theme`colors.springgreen.dark`};
          }
        }
      }
    }
  }
`

const ErrorContainer = styled.div`
  ${tw`font-body text-white text-center p-3`}
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 21px;
`

const Start2 = ({ data, location }) => {
  const { page, gateSetting, gateContent } = data.gcms
  const stripe = useRef()
  const [formEnabled, setFormEnabled] = useState(true)
  const [waiting, setWaiting] = useState(true)
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [paymentError, setPaymentError] = useState(false)
  const [email, setEmail] = useState(null)
  const [plan, setPlan] = useState("")
  const [maxPlanPrice, setMaxPlanPrice] = useState(118800)
  const [plans, setPlans] = useState([])
  const [coupon, setCoupon] = useState(null)
  const [specialOffer, setSpecialOffer] = useState(null)
  const couponContext = useContext(CouponContext)

  const showGate = gateSetting?.value && gateSetting.value === "on"
  const gate = gateContent && gateContent.length ? gateContent[0] : null

  const [state, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstname: "",
      lastname: "",
      company: "",
      country: "",
      password: "",
      plan: "",
      plan_revenue: "",
      token: "",
      coupon: "",
    }
  )

  const waitOptions = {
    loop: true,
    autoplay: true,
    animationData: waitAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const checkOptions = {
    loop: false,
    autoplay: true,
    animationData: checkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const bgStack = [
    "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 46.71%, #000000 100%)",
    `url(${BgStart})`,
  ]

  useEffect(() => {
    // get and verify the email token
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")
    const error = urlParams.get("error")

    dispatch({ token })

    if (error) {
      setPaymentError(true)
    }

    const fetchData = async (token) => {
      stripe.current = window.Stripe(process.env.GATSBY_STRIPE_KEY)
      const url = `${process.env.GATSBY_DEMAND360_SIGNUP_API}/verify_email?token=${token}`
      let body
      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": process.env.GATSBY_STRIPE_KEY,
          },
        })
        body = await response.json()
      } catch (err) {
        body = {}
      }
      if (body?.verified && body?.plans?.length > 0) {
        setEmail(body.email)
        const sorted = body.plans.sort((a, b) => b.amount - a.amount)
        const planData = sorted.map((p) => {
          p.yearly =
            p.interval === "month" ? p.amount_decimal * 12 : p.amount_decimal
          return p
        })
        setPlans(planData)
        const selectedPlan = planData[0]
        setPlan(selectedPlan.id)
        dispatch({
          plan: `TV360-Monitor-${selectedPlan.nickname}`,
          plan_revenue: selectedPlan.amount_decimal / 100,
        })
        const maxPlan = (acc, cur) => Math.max(acc, cur.yearly)
        const maxPlanPrice = planData.reduce(maxPlan, 0)
        setMaxPlanPrice(maxPlanPrice)
        setWaiting(false)
      } else {
        setError(true)
        if (body?.error?.message) {
          setErrorMessage(body?.error?.message)
        }
      }
    }

    if (!ready) {
      fetchData(token)
    }
  }, [ready, dispatch])

  useEffect(() => {
    async function fetchSpecialOffer() {
      const rawSpecialOffer = await couponContext.getSpecialOffer()

      if (rawSpecialOffer) {
        const { applicablePlan } = rawSpecialOffer
        const plan =
          (plans || []).find(
            (plan) => plan.nickname?.toLowerCase() === applicablePlan
          ) || null

        if (plan) {
          switchPlan(plan.id)
        }

        setSpecialOffer({ ...rawSpecialOffer, plan })
      }
    }

    fetchSpecialOffer()
  }, [coupon, plans])

  const getCoupon = async () => {
    const coupon = await couponContext.getCoupon()
    if (coupon) {
      dispatch({ coupon: coupon.id })
      setCoupon(coupon)
    }
  }

  useEffect(() => {
    if (couponContext.coupon) {
      getCoupon()
    }
  }, [couponContext])

  function handleChange(event) {
    const target = event.target

    dispatch({
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    })
  }

  function changePlan(event) {
    switchPlan(event.target.value)
  }

  function switchPlan(planId) {
    const plan = plans.find((p) => p.id === planId)
    setPlan(planId)
    if (plan) {
      dispatch({
        plan: `TV360-Monitor-${plan.nickname}`,
        plan_revenue: plan.amount_decimal / 100,
      })
    }
  }

  const onSubmit = async (event) => {
    setFormEnabled(false)
    event.preventDefault()
    const url = `${process.env.GATSBY_DEMAND360_SIGNUP_API}/update_user`
    const fields = state
    if (couponApplies(plans.find((p) => p.id === plan))) {
      fields.coupon = coupon.id
    } else {
      fields.coupon = ""
    }
    const data = {
      email,
      plan_id: plan,
      fields,
    }

    let body
    try {
      const response = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": process.env.GATSBY_STRIPE_KEY,
        },
        body: JSON.stringify(data),
      })
      body = await response.json()
    } catch (err) {
      body = { error: "Error" }
    }
    if (body?.error) {
      setFormEnabled(true)
      setError(true)
      setErrorMessage(body?.error?.message || "")
    } else {
      const { error } = await stripe.current.redirectToCheckout({
        sessionId: body.session_id,
      })
      if (error) {
        setFormEnabled(true)
        setError(true)
        console.warn("Error:", error)
      }
    }
  }

  const couponApplies = (toPlan) => {
    let planOK = false
    if (coupon?.metadata?.plan_applicable) {
      if (
        (typeof coupon.metadata.plan_applicable === "string" &&
          coupon.metadata.plan_applicable === toPlan.id) ||
        coupon?.metadata?.plan_applicable?.toLowerCase() ===
          toPlan?.nickname?.toLowerCase()
      ) {
        planOK = true
      } else if (
        typeof coupon.metadata.plan_applicable === "object" &&
        (coupon.metadata.plan_applicable.includes(toPlan.id) ||
          coupon.metadata.plan_applicable
            .map((p) => p.toLowerCase())
            .includes(toPlan.nickname.toLowerCase()))
      ) {
        planOK = true
      }
    } else {
      planOK = true
    }
    return planOK && coupon?.valid
  }

  const applyCoupon = (plan, period) => {
    const amount =
      period === "month"
        ? (plan.interval === "year"
            ? plan.amount_decimal / 12
            : plan.amount_decimal) / 100
        : plan.yearly / 100
    if (!couponApplies(plan)) {
      return amount
    }
    if (coupon?.percent_off) {
      if (coupon?.duration === "forever") {
        return amount * ((100 - coupon.percent_off) / 100)
      } else if (coupon?.duration === "repeating") {
        if (period === "month") {
          return amount * ((100 - coupon.percent_off) / 100)
        } else {
          return (
            (amount / 12) *
              ((100 - coupon.percent_off) / 100) *
              coupon?.duration_in_months +
            (amount / 12) * (12 - coupon?.duration_in_months)
          )
        }
      } else if (coupon?.duration === "once") {
        if (plan.interval === "year" || period === "month") {
          return amount * ((100 - coupon.percent_off) / 100)
        } else {
          return (
            (amount / 12) * ((100 - coupon.percent_off) / 100) +
            (amount / 12) * 11
          )
        }
      }
    } else if (coupon?.amount_off) {
      if (coupon?.duration === "forever") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - coupon.amount_off / 100)
          } else {
            return Math.max(0, amount / 12 - coupon.amount_off / 100) * 12
          }
        } else {
          if (period === "month") {
            return Math.max(0, amount * 12 - coupon.amount_off / 100) / 12
          } else {
            return Math.max(0, amount - coupon.amount_off / 100)
          }
        }
      } else if (coupon?.duration === "once") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - coupon.amount_off / 100)
          } else {
            return (
              Math.max(0, amount / 12 - coupon.amount_off / 100) +
              (amount / 12) * 11
            )
          }
        } else if (plan.interval === "year") {
          if (period === "month") {
            return Math.max(0, (amount * 12 - coupon.amount_off / 100) / 12)
          } else {
            return Math.max(0, amount - coupon.amount_off / 100)
          }
        }
      } else if (coupon?.duration === "repeating") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - coupon.amount_off / 100)
          } else {
            return (
              Math.max(0, amount / 12 - coupon.amount_off / 100) *
                coupon.duration_in_months +
              (amount / 12) * Math.max(12 - coupon.duration_in_months, 0)
            )
          }
        } else {
          if (period === "month") {
            return Math.max(0, amount * 12 - coupon.amount_off / 100) / 12
          } else {
            return Math.max(0, amount - coupon.amount_off / 100)
          }
        }
      }
    } else {
      return amount
    }
  }

  return (
    <ModalLayout location={location}>
      {!!page && <SEO title={page.title} description={page.description} />}
      <BasicBackgroundSection bgStack={bgStack}>
        <Container className="text-white min-h-screen">
          <div className="w-full py-8 flex flex-wrap justify-center">
            <div className="py-8 overflow-visible flex flex-col items-center">
              {ready && (
                <>
                  {!showGate && (
                    <>
                      <img src={MailIcon} alt="mail icon" />
                      <StyledBlock>
                        <h3 className="mt-9">Email Verified!</h3>
                        <p className="medium-subtitle mt-3 m:mt-5">
                          Only 1 step before you can unlock the magic of content
                        </p>
                      </StyledBlock>
                    </>
                  )}
                  {!!showGate && (
                    <>
                      {gate.featuredImage && (
                        <img
                          src={gate.featuredImage.url}
                          className="w-1/3 pb-8"
                          alt=""
                        />
                      )}
                      <GateStyle
                        dangerouslySetInnerHTML={{
                          __html: gate?.headerContent?.html,
                        }}
                      />
                    </>
                  )}
                  <Form className="mt-16" onSubmit={onSubmit}>
                    <fieldset disabled={!formEnabled}>
                      <div className="flex flex-col m:flex-row w-full m:space-x-6">
                        <TextInput
                          type="text"
                          name="firstname"
                          onChange={handleChange}
                          required={true}
                          value={state.firstname}
                          placeholder="First Name"
                          icon={IconUser}
                          className="w-full"
                        />
                        <TextInput
                          type="text"
                          name="lastname"
                          onChange={handleChange}
                          placeholder="Last Name"
                          required={true}
                          value={state.lastname}
                          icon={IconUser}
                          className="w-full"
                        />
                      </div>
                      <TextInput
                        type="text"
                        name="company"
                        onChange={handleChange}
                        placeholder="Company Name"
                        required={true}
                        value={state.company}
                        icon={IconCompany}
                      />
                      <CountryDropdown
                        classes={state.country ? "valid" : ""}
                        value={state.country}
                        onChange={(country) => dispatch({ country: country })}
                      />
                      <TextInput
                        type="password"
                        className="mb-6"
                        name="password"
                        onChange={handleChange}
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                        title={
                          page?.extraData?.passwordHint ||
                          `Please choose a password with a minimum of 8 characters, and a maximum of 20 characters. The password must have at least 1 lower case character, 1 upper case character and 1 number.`
                        }
                        placeholder="Password"
                        required={true}
                        value={state.password}
                        icon={IconLock}
                      />
                      <Hint>
                        {page?.extraData?.passwordHint ||
                          `Please choose a password with a minimum of 8 characters, and a maximum of 20 characters. The password must have at least 1 lower case character, 1 upper case character and 1 number.`}
                      </Hint>
                      <hr className="faded" />
                      {coupon && (
                        <>
                          <Coupon>
                            <h3 className="border-b-2">Coupon Activated</h3>
                          </Coupon>
                          <Hint className="text-center">{coupon.name}</Hint>
                          <hr className="faded" />
                        </>
                      )}
                      {!coupon && couponContext.coupon && (
                        <>
                          <Coupon>
                            <h3 className="text-white">Coupon not valid</h3>
                          </Coupon>
                          <Hint className="text-center">
                            Apologies, but this coupon is no longer valid.
                          </Hint>
                          <hr className="faded" />
                        </>
                      )}

                      {!specialOffer && (
                        <>
                          <div className="font-display text-lg mt-6">
                            Payment Options
                          </div>

                          {plans.map((p) => (
                            <Checkbox
                              key={p.id}
                              className={plan === p.id ? "checked" : ""}
                            >
                              <label className="flex flex-row items-start">
                                <input
                                  name="plan"
                                  onChange={changePlan}
                                  required={true}
                                  type="radio"
                                  value={p.id}
                                  checked={plan === p.id}
                                />
                                <div className="flex flex-row flex-wrap m:flex-nowrap justify-between w-full">
                                  <div className="uppercase whitespace-nowrap pr-4 font-display">
                                    {p.nickname}
                                    {p.yearly < maxPlanPrice && (
                                      <span className="text-springgreen-light">
                                        {" "}
                                        (Save{" "}
                                        {Math.round(
                                          ((maxPlanPrice -
                                            applyCoupon(p, "year") * 100) /
                                            maxPlanPrice) *
                                            100
                                        )}
                                        %)
                                      </span>
                                    )}
                                  </div>
                                  <div className="m:text-right">
                                    USD{" "}
                                    <span
                                      className={`font-display
                                        ${
                                          couponApplies(p)
                                            ? "line-through font-body"
                                            : p.interval === "year"
                                            ? "text-springgreen-light"
                                            : ""
                                        } `}
                                    >
                                      $
                                      {Math.round(
                                        (p.interval === "year"
                                          ? couponApplies(p)
                                            ? maxPlanPrice / 12
                                            : p.amount_decimal / 12
                                          : p.amount_decimal) / 100
                                      )}
                                    </span>{" "}
                                    <span
                                      className={
                                        couponApplies(p) ||
                                        p.interval === "year"
                                          ? "text-springgreen-light font-display"
                                          : ""
                                      }
                                    >
                                      {couponApplies(p) &&
                                        `$${Math.round(
                                          applyCoupon(p, "month")
                                        )} `}
                                    </span>
                                    per month&nbsp;/&nbsp;
                                    <span
                                      className={`font-display
                                        ${
                                          couponApplies(p)
                                            ? "line-through font-body"
                                            : p.interval === "year"
                                            ? "text-springgreen-light"
                                            : ""
                                        }`}
                                    >
                                      $
                                      {Math.round(
                                        (p.interval === "year" &&
                                        couponApplies(p)
                                          ? maxPlanPrice
                                          : p.yearly) / 100
                                      )}
                                    </span>{" "}
                                    <span
                                      className={
                                        couponApplies(p) ||
                                        p.interval === "year"
                                          ? "font-display"
                                          : ""
                                      }
                                    >
                                      {couponApplies(p) &&
                                        `$${Math.round(
                                          applyCoupon(p, "year")
                                        )} `}
                                    </span>
                                    per year
                                  </div>
                                </div>
                              </label>
                            </Checkbox>
                          ))}

                          {page?.extraData?.paymentHint && (
                            <div className="text-center m:text-left font-italic italic mt-5 text-sm text-priority-darkP3">
                              {page?.extraData?.paymentHint}
                            </div>
                          )}

                          <Submit>Review & Pay</Submit>
                        </>
                      )}

                      {specialOffer && (
                        <>
                          <div className="font-display text-lg mt-6">
                            {specialOffer.title}
                          </div>

                          <Checkbox>
                            <label className="flex flex-row items-start">
                              <input
                                name="plan"
                                onChange={changePlan}
                                required={true}
                                type="radio"
                                value={specialOffer.plan.id}
                                checked={true}
                              />
                              <div className="flex flex-row flex-wrap m:flex-nowrap justify-between w-full font-bold">
                                {specialOffer.description}
                              </div>
                            </label>
                          </Checkbox>

                          <Submit>{specialOffer.cta}</Submit>

                          <div className="text-left font-italic italic mt-3 text-sm">
                            {specialOffer.terms}
                          </div>
                        </>
                      )}
                    </fieldset>
                  </Form>
                </>
              )}
              {!ready && (
                <Message>
                  {!waiting && (
                    <div className="check animation">
                      <Lottie
                        options={checkOptions}
                        height={72}
                        width={72}
                        eventListeners={[
                          {
                            eventName: "complete",
                            callback: () => {
                              setReady(true)
                              sendConversionEvent({
                                index: process.env.GATSBY_ALGOLIA_INDEX_NAME,
                                event: "Signup Start",
                              })
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
                  {!!waiting && (
                    <div className="wait animation">
                      <Lottie options={waitOptions} height={72} width={72} />
                    </div>
                  )}
                  <h1>Verifying your email...</h1>
                </Message>
              )}
              {!!error && (
                <div className="text-base font-body text-neonred text-center">
                  {errorMessage || "There was an error. Please try again."}
                </div>
              )}
              {!!paymentError && (
                <ErrorContainer>
                  To start your Monitor subscription, please click REVIEW & PAY
                  and enter your billing details.
                </ErrorContainer>
              )}
            </div>
          </div>
        </Container>
      </BasicBackgroundSection>
    </ModalLayout>
  )
}

export default Start2

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/start/2" }) {
        ...PageData
      }
      gateSetting: websiteSetting(where: { name: "Signup gate" }) {
        value
      }
      gateContent: contentBlocks(where: { identifier: "signup-gate" }) {
        headerContent {
          html
        }
        featuredImage {
          url
        }
      }
    }
  }
`
